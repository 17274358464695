const gatewayUrl = 'https://api-qat.service.ford.com/agenda/gateway';

export const environment = {
  name: 'qa',
  production: false,
  baseUrl: `${gatewayUrl}/agenda-backoffice/api/internal`,
  transformationUrl: `${gatewayUrl}/transformation/api/internal`,
  dealerMediaUri: `${gatewayUrl}/agenda-dealermedia/api/internal`,
  dealerMediaPublicUri: `${gatewayUrl}/agenda-dealermedia/api`,
  agendaOwnersUrl: `${gatewayUrl}/agenda-owners/api/internal`,
  agendaDealerUrl: `${gatewayUrl}/agenda-dealer/api/internal`,
  agendaCampaigns: `${gatewayUrl}/agenda-campaigns/api/internal`,
  agendaUrl: 'https://wwwqa.agenda.ford.com',
};
